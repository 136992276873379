import ReactMarkdown from 'react-markdown'

import './Card.css'

export default function Card ({
  card,
  setCard,
  copyCard
}: {
  card: CardType
  setCard: (value: CardType | undefined) => void,
  copyCard: () => void
}) {
  return <div>
    <div className="Card no-screen">
      <h1>{card.name}</h1>
      <small>
        <ReactMarkdown>
          {card.flavor || ''}
        </ReactMarkdown>
      </small>
      <div style={{ textAlign: 'left' }}>
        <ReactMarkdown>
          {card.content}
        </ReactMarkdown>
      </div>
      <div className="charges">
        {!!card.charges && <h2>Charges</h2>}
        <div>
        {Array(card.charges).fill(0).map(() => <div></div>)}
        </div>
      </div>
    </div>
    <div
      className="Card no-print"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <input
        placeholder="Name"
        value={card.name}
        onChange={event => setCard({
          ...card,
          name: event.target.value,
        })}
      />
      <input
        placeholder="Flavor"
        value={card.flavor}
        onChange={event => setCard({
          ...card,
          flavor: event.target.value,
        })}
      />
      <textarea
        style={{
          height: '100%'
        }}
        placeholder="content (markdown)"
        value={card.content}
        onChange={event => setCard({
          ...card,
          content: event.target.value,
        })}
      />
      <input
        placeholder="Charges"
        type="number"
        value={card.charges}
        onChange={event => setCard({
          ...card,
          charges: Number.parseInt(event.target.value),
        })}
      />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
      }}>
        <button onClick={() => setCard(undefined)}>Delete</button>
        <button onClick={copyCard}>Copy</button>
      </div>
      
    </div>
  </div>
}