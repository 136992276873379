import React, { useEffect, useState } from 'react'
import './App.css'
import Card from './components/Card'
import Page from './components/Page'


function App() {
  const cache = localStorage.getItem('cards')
  const [cards, setCards] = useState<CardType[]>((cache && JSON.parse(cache)) || [])

  useEffect(() => {
    const cache = localStorage.getItem('cards')
    if (!cache) {
      localStorage.setItem('cards', JSON.stringify(cards))
      return
    }
    if (cache === JSON.stringify(cards)) return
    localStorage.setItem('cards', JSON.stringify(cards))
    if (!cards) {
      setCards(JSON.parse(cache))
    }
  }, [cards])
  return (
    <div className="App">
      <div  className="no-print" style={{ minWidth: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100vh'}}>
        <div>
          Click Here --&gt;&nbsp;
          <button
            className="no-print"
            onClick={() => setCards([
              ...cards,
              {
              name: '',
              content: '',
              flavor: '',
              charges: 0
              }
            ])}
          >Add Card</button>
          <button onClick={() => {
            setCards([])
          }}>
            Clear All
          </button>
        </div>
      <div  className="no-print">
        <h6>Save or Load</h6>
        <textarea
        style={{ width: '90%', minHeight: '50vh' }}
          value={JSON.stringify(cards, undefined, 2)}
          onChange={event => setCards(JSON.parse(event.target.value))}></textarea>
      </div>
      </div>
      <div>
           {Array(Math.ceil(cards.length / 8)).fill(0).map((_x, pageIndex) => <Page>
            {cards.map((card, index): [CardType, number] => [card, index]).slice(pageIndex * 8, pageIndex* 8 + 8).map(([card, cardIndex]) => <Card
              card={card}
              copyCard={() => setCards([
                ...cards,
                card
              ])}
              setCard={(newValue: CardType | undefined) => {
                console.log(newValue, cardIndex)
                if (!newValue) {
                  setCards([
                    ...cards.slice(0, cardIndex),
                    ...cards.slice(cardIndex + 1)
                  ])
                  return
                }
                setCards([
                  ...cards.slice(0, cardIndex),
                  newValue,
                  ...cards.slice(cardIndex + 1)
                ])
              }}
            />)}
          </Page>)}
      </div>
    </div>
  )
}

export default App
